import { useIntl } from 'react-intl';
import { SponsoredLegalInfo } from 'types/Product';

const useSigning = () => {
  const { formatMessage } = useIntl();

  const getSponsoredSigning = (legalInfo?: SponsoredLegalInfo) => ({
    className: 'text-foreground',
    hex: '#F8F8F8',
    id: 'sponsored',
    label: formatMessage({ id: 'sponsored_product_signing' }),
    legalInfo: legalInfo,
  });

  return { getSponsoredSigning };
};

export default useSigning;
