import Icon from 'components/Icon/Icon';
import { ProductLink } from 'components/Router';
import TileTitle from 'components/Tile/TileTitle/TileTitle';
import { ICON_NAMES } from 'constants/icon';
import { FontSize, FontTag, FontType, Spacer, TextAlign } from 'constants/styling';
import { ReactNode } from 'react';
import { Product } from 'types/Product';

interface ProductTitleProps {
  brandSize?: FontSize;
  children?: ReactNode;
  className?: string;
  eyebrow?: string;
  isLink?: boolean;
  marginBottom?: Spacer;
  maxLines?: number;
  onCrossClick?: () => void;
  product: Product;
  tag?: FontTag;
  target?: string;
  textAlign?: TextAlign;
  titleSize?: FontSize;
  type?: FontType;
  withBrand?: boolean;
}

const ProductTitle = ({
  brandSize,
  children,
  className,
  eyebrow,
  isLink = true,
  marginBottom = 0,
  maxLines,
  onCrossClick,
  product,
  tag = 'div',
  textAlign = 'left',
  titleSize = 125,
  type,
  withBrand = true,
  ...props
}: ProductTitleProps) => {
  const brand = eyebrow || product?.brand?.name;
  const name = product?.name;

  const content = (
    <>
      {children || name}
      {!!onCrossClick && (
        <Icon
          type="custom"
          name={ICON_NAMES.TIMES_CIRCLE}
          onClick={(e) => {
            onCrossClick();
            e.preventDefault(); // Prevents navigating to the product
          }}
          size={75}
        />
      )}
    </>
  );

  const link = (
    <TileTitle
      className={className}
      eyebrow={withBrand ? brand : undefined}
      eyebrowSize={brandSize}
      marginBottom={marginBottom}
      maxLines={maxLines || 2}
      tag={tag}
      textAlign={textAlign}
      titleSize={titleSize}
      type={type}
    >
      {content}
    </TileTitle>
  );

  if (!isLink) {
    return link;
  }

  return (
    <ProductLink
      atp={product?.atp}
      brand={product?.brand}
      carrier={product?.carrier}
      className={className}
      code={product?.code}
      categories={product?.categories}
      name={product?.name}
      price={product?.price}
      quantity={product?.quantity}
      queryId={product?.queryId}
      replaceUrl={undefined}
      url={product?.url}
      {...props}
    >
      {link}
    </ProductLink>
  );
};

export default ProductTitle;
