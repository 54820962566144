import { useIntl } from 'react-intl';
import { SponsoredLegalInfo } from 'types/Product';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from 'ui/Dialog/Dialog';
import { Text } from 'ui/Text/Text';

type Props = {
  legalInfo: SponsoredLegalInfo;
  onClose: () => void;
  open: boolean;
};

const SponsoredLegalModal = ({ legalInfo, onClose, open }: Props) => {
  const { formatMessage } = useIntl();
  const { behalf, paid } = legalInfo;

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            {formatMessage({
              id: 'general_criteo_ad_modal_title',
            })}
          </DialogTitle>
        </DialogHeader>
        <div>
          <Text html={formatMessage({ id: 'general_dsa_ad_modal_main' })} />
          <ul className="list-disc pl-4">
            <li>{formatMessage({ id: 'general_dsa_ad_modal_behalf' }, { behalf })}</li>
            {paid && <li>{formatMessage({ id: 'general_dsa_ad_modal_paid' }, { paid })}</li>}
          </ul>
        </div>
      </DialogContent>
    </Dialog>
  );
};
export default SponsoredLegalModal;
